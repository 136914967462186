import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_LANGUAGE } from '@lib/constants';
import { AppLanguage } from '@lib/services/language';

@Component({
    selector: 'app-language-switcher',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './language-switcher.component.html',
})
export class LanguageSwitcherComponent {
    @Input() customId!: string;
    @Input() currentLanguage: AppLanguage = DEFAULT_LANGUAGE;
    @Output() changeLanguage = new EventEmitter<AppLanguage>();

    switchers: { [key: string]: { checkbox: string; dot: string } } = {
        one: {
            checkbox: 'peer/switcher1',
            dot: `this.currentLanguage === 'id'
                    ? 'peer-checked/switcher1:translate-x-full'
                    : 'peer-checked/switcher1:translate-x-0'`,
        },
        two: {
            checkbox: 'peer/switcher2',
            dot: `this.currentLanguage === 'id'
                ? 'peer-checked/switcher1:translate-x-full'
                : 'peer-checked/switcher1:translate-x-0'`,
        },
    };

    toggleLanguage(): void {
        this.currentLanguage;
        const language: AppLanguage = this.currentLanguage === 'id' ? 'en' : 'id';
        this.changeLanguage.emit(language);
    }
}
