<div class="flex justify-center items-center">
    <label
        [attr.for]="customId"
        class="flex items-center cursor-pointer"
    >
        <!-- toggle -->
        <div class="relative">
            <!-- input -->
            <input
                type="checkbox"
                [attr.id]="customId"
                class="sr-only {{ switchers[customId].checkbox }}"
                (change)="toggleLanguage()"
                [value]="currentLanguage"
            />
            <!-- line -->
            <div class="block bg-gray-700 xs:bg-zinc-200 dark:bg-gray-700 w-14 h-8 rounded-full"></div>
            <!-- dot -->
            <div
                class="{{ switchers[customId].dot }} {{
                    currentLanguage === 'id' ? 'translate-x-0' : 'translate-x-full'
                }} dot absolute left-1 top-1 fi fi-{{
                    currentLanguage === 'id' ? 'id' : 'gb'
                }} bg-cover w-6 h-6 rounded-full transition"
            ></div>
            <!-- dot -->
        </div>
    </label>
</div>
