<button
    class="flex relative w-12 h-12 hover:bg-zinc-200 dark:hover:bg-gray-700 justify-center items-center rounded-full"
    (click)="toggleTheme()"
>
    <span
        class="{{
            currentTheme === 'dark' ? 'icon-[carbon--sun]' : 'icon-[carbon--moon]'
        }} text-2xl dark:text-gray-300 block"
    ></span>
</button>
